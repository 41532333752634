import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [16,[2],[3]],
		"/_component/[...componentName]": [117],
		"/(app)/announcements": [19,[2],[3]],
		"/(app)/announcements/create": [21,[2],[3]],
		"/(app)/announcements/[id]/edit": [20,[2],[3]],
		"/(app)/backrooms": [22,[2],[3]],
		"/(app)/birthdays": [23,[2],[3]],
		"/(app)/bookings": [24,[2],[3]],
		"/(app)/bookings/[code]": [25,[2],[3]],
		"/(app)/changelog": [26,[2],[3]],
		"/(app)/claim-code": [27,[2],[3]],
		"/(app)/claim-code/[code]": [28,[2],[3]],
		"/(external)/connect/google/callback": [115,[14]],
		"/(app)/credits": [29,[2],[3]],
		"/(app)/documents": [30,[2,5],[3]],
		"/(app)/documents/create": [37,[2],[3]],
		"/(app)/documents/[major]": [31,[2,5],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]": [32,[2,5],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]": [33,[2,5],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/create": [36,[2,5],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/[document]": [34,[2,5],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/[document]/edit": [35,[2,5],[3]],
		"/(app)/events/[[week=date]]": [38,[2],[3]],
		"/(app)/events/[id]": [39,[2,6],[3]],
		"/(app)/events/[id]/bookings": [40,[2,6],[3]],
		"/(app)/events/[id]/edit": [41,[2,6,7],[3]],
		"/(app)/events/[id]/edit/banned": [42,[2,6,7],[3]],
		"/(app)/events/[id]/edit/contact": [43,[2,6,7],[3]],
		"/(app)/events/[id]/edit/description": [44,[2,6,7],[3]],
		"/(app)/events/[id]/edit/image": [45,[2,6,7],[3]],
		"/(app)/events/[id]/edit/links": [46,[2,6,7],[3]],
		"/(app)/events/[id]/edit/managers": [47,[2,6,7],[3]],
		"/(app)/events/[id]/edit/recurrence": [48,[2,6,7],[3]],
		"/(app)/events/[id]/edit/ticket-groups/[group]": [49,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets": [50,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]": [51,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/counting": [52,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/group": [53,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/invited": [54,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/links": [55,[2,6,7],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/payment": [56,[2,6,7],[3]],
		"/(app)/events/[id]/edit/visibility": [57,[2,6,7],[3]],
		"/(app)/events/[id]/join/[code]": [58,[2,6],[3]],
		"/(app)/events/[id]/scan": [59,[2,6],[3]],
		"/(app)/groups/[uid]/edit": [60,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/bank-accounts": [61,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/bio": [62,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/links": [63,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/members/bulk": [64,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/pages": [65,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/pages/[...page]": [66,[2,8,9],[3]],
		"/(app)/groups/[uid]/edit/type": [67,[2,8,9],[3]],
		"/(app)/groups/[uid]/members": [68,[2,8],[3]],
		"/(app)/help": [69,[2],[3]],
		"/(external)/kiosk": [116,[14]],
		"/(app)/login": [~70,[2],[3]],
		"/(app)/login/done": [71,[2],[3]],
		"/(app)/login/forgotten": [72,[2],[3]],
		"/(app)/login/reset/[token]": [73,[2],[3]],
		"/(app)/logout": [~74,[2],[3]],
		"/(app)/logs": [75,[2],[3]],
		"/(app)/more": [76,[2],[3]],
		"/(app)/notifications": [77,[2],[3]],
		"/(app)/posts/[id]": [78,[2],[3]],
		"/(app)/posts/[id]/edit": [79,[2,10],[3]],
		"/(app)/posts/[id]/edit/body": [80,[2,10],[3]],
		"/(app)/posts/[id]/edit/event": [81,[2,10],[3]],
		"/(app)/posts/[id]/edit/links": [82,[2,10],[3]],
		"/(app)/posts/[id]/edit/picture": [83,[2,10],[3]],
		"/(app)/posts/[id]/edit/visibility": [84,[2,10],[3]],
		"/(app)/quick-signups/create": [85,[2],[3]],
		"/(app)/quick-signups/manage": [86,[2],[3]],
		"/(app)/quick-signups/qr/[code]": [~87,[11]],
		"/(app)/reports": [88,[2],[3]],
		"/(app)/reports/[number]": [89,[2],[3]],
		"/(app)/search/[[q]]": [90,[2],[3]],
		"/(app)/services": [91,[2],[3]],
		"/(app)/services/manage": [93,[2],[3]],
		"/(app)/services/submit": [94,[2],[3]],
		"/(app)/services/[id]/edit": [92,[2],[3]],
		"/(app)/set-password": [95,[2],[3]],
		"/(app)/settings": [96,[2,12],[3]],
		"/(app)/settings/theme": [97,[2,12],[3]],
		"/(app)/signups": [98,[2],[3]],
		"/(app)/signups/edit/[email]": [99,[2],[3]],
		"/signup": [118,[15]],
		"/signup/finish/[token]": [~120,[15]],
		"/signup/[qrcode]": [119,[15]],
		"/(app)/student-associations/[uid]/edit/pages": [101,[2],[3]],
		"/(app)/student-associations/[uid]/edit/pages/[...page]": [102,[2],[3]],
		"/(app)/student-associations/[uid]/[...page]": [100,[2],[3]],
		"/(app)/users/[uid]/edit": [103,[2,13],[3]],
		"/(app)/users/[uid]/edit/bio": [104,[2,13],[3]],
		"/(app)/users/[uid]/edit/contributions": [105,[2,13],[3]],
		"/(app)/users/[uid]/edit/curriculum": [106,[2,13],[3]],
		"/(app)/users/[uid]/edit/email": [107,[2,13],[3]],
		"/(app)/users/[uid]/edit/family": [108,[2,13],[3]],
		"/(app)/users/[uid]/edit/links": [109,[2,13],[3]],
		"/(app)/users/[uid]/edit/name": [110,[2,13],[3]],
		"/(app)/users/[uid]/edit/other-emails": [111,[2,13],[3]],
		"/(app)/users/[uid]/edit/permissions": [112,[2,13],[3]],
		"/(app)/validate-email/[token]": [113,[2],[3]],
		"/(app)/welcome": [114,[2],[3]],
		"/(app)/[uid=uid]": [17,[2,4],[3]],
		"/(app)/[uid=uid]/[...page]": [18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';